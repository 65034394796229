import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const ExpansionPanel = withStyles({
	root: {
		border: '1px solid rgba(0, 0, 0, .125)',
		boxShadow: 'none',
		'&:not(:last-child)': {
			borderBottom: 0
		},
		'&:before': {
			display: 'none'
		},
		'&$expanded': {
			margin: 'auto'
		}
	},
	expanded: {}
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
	root: {
		backgroundColor: 'rgba(0, 0, 0, .025)',
		borderBottom: '1px solid rgba(0, 0, 0, .125)',
		marginBottom: -1,
		minHeight: 56,
		'&$expanded': {
			minHeight: 56
		}
	},
	content: {
		'&$expanded': {
			margin: '12px 0'
		}
	},
	expanded: {}
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles(theme => ({
	root: {
		padding: theme.spacing(3),
		display: 'block'
	}
}))(MuiExpansionPanelDetails);

export default function CollapsePanels(props) {
	const [expanded, setExpanded] = React.useState();

	const handleChange = panel => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	const panels = props.config.map(item => {
		return (
			<ExpansionPanel
				square
				key={item.id}
				expanded={expanded === item.id}
				onChange={handleChange(item.id)}
			>
				<ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
					<Typography>{item.title}</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails>{item.content}</ExpansionPanelDetails>
			</ExpansionPanel>
		);
	});

	return (
		<React.Fragment>
			{panels}
		</React.Fragment>
	);
}

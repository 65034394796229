import { SET_MOBILE, SET_ACTIVE_GUIDE_PAGE } from './types';

export const setMobile = type => dispatch => {
	dispatch({
		type: SET_MOBILE,
		payload: type
	});
};

export const setActiveGuidePage = page => dispatch => {
	dispatch({
		type: SET_ACTIVE_GUIDE_PAGE,
		payload: page
	});
};

import React from 'react';
import { Provider } from 'react-redux';
import store from './store';
import AppLayout from './components/AppLayout';
import { BrowserRouter as Router, Route } from 'react-router-dom';

function App() {
	return (
		<Provider store={store}>
			<Router>
				<Route
					path="/"
					render={({ location }) => {
						return <AppLayout location={location} />;
					}}
				/>
			</Router>
		</Provider>
	);
}

export default App;

import React from 'react';
import ReactDOM from 'react-dom';
import './stylesheets/css/index.css';
import App from './App.jsx';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons/faBoxOpen';
library.add(faBoxOpen);

ReactDOM.render(<App />, document.getElementById('root'));

import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import ImageIcon from '@material-ui/icons/Image';
import { makeStyles } from '@material-ui/core/styles';
import Gallery from './Gallery';

const useStyles = makeStyles(theme => ({
	leftIcon: {
		marginRight: theme.spacing(1)
	},
}));

export default function ResponsiveDialog(props) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	function handleClickOpen() {
		setOpen(true);
	}

	function handleClose() {
		setOpen(false);
	}

	return (
		<div>
			<Button variant="outlined" color="primary" onClick={handleClickOpen}>
				<ImageIcon className={classes.leftIcon} />
				Instruction images
			</Button>
			<Dialog fullScreen open={open} onClose={handleClose}>
				<AppBar
					className="app-bar"
					position="sticky"
					color="default"
					style={{ backgroundColor: 'white' }}
				>
					<Toolbar>
						<IconButton onClick={handleClose} style={{ marginLeft: -12 }}>
							<CloseIcon />
						</IconButton>
						<Typography
							style={{ marginLeft: 8 }}
							component="span"
							noWrap
						>
							{props.title}
						</Typography>
					</Toolbar>
				</AppBar>
				<Gallery images={props.images} />
			</Dialog>
		</div>
	);
}

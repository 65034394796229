import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import ImageElement from './ImageElement';
import CollapsePanels from './CollapsePanels';

const W = function(props) {
	return <b style={{ fontWeight: 500 }}>{props.children}</b>
};

export const simLockContent = (
	<React.Fragment>
		<Typography paragraph>
			<W>Navigate:</W> Settings → Biometrics and security → Other security settings → Set up SIM card lock →
			Lock SIM card
		</Typography>
		<ImageElement
			title="Disable SIM lock"
			images={[
				{
					label: '1',
					imgPath: 'images/faq/sim_lock/1.jpg'
				},
				{
					label: '2',
					imgPath: 'images/faq/sim_lock/2.jpg'
				},
				{
					label: '3',
					imgPath: 'images/faq/sim_lock/3.jpg'
				},
				{
					label: '4',
					imgPath: 'images/faq/sim_lock/4.jpg'
				},
				{
					label: '5',
					imgPath: 'images/faq/sim_lock/5.jpg'
				},
				{
					label: '6',
					imgPath: 'images/faq/sim_lock/6.jpg'
				}
			]}
		/>
	</React.Fragment>
);

export const enableMobileDataContent = (
	<React.Fragment>
		<Typography paragraph><W>Navigate:</W> Settings → Connections → Data usage → Mobile data</Typography>
		<ImageElement
			title="Enable mobile data"
			images={[
				{
					label: '1',
					imgPath: 'images/faq/mobile_data/1.png'
				},
				{
					label: '2',
					imgPath: 'images/faq/mobile_data/2.png'
				},
				{
					label: '3',
					imgPath: 'images/faq/mobile_data/3.png'
				}
			]}
		/>
	</React.Fragment>
);

export const alwaysOnDisplayContent = (
	<React.Fragment>
		<Typography paragraph>
			<W>Navigate:</W> Settings → Lock screen → Always On Display
		</Typography>
		<ImageElement
			title="Disable Always On Display"
			images={[
				{
					label: '1',
					imgPath: 'images/faq/always_on_display/1.jpg'
				},
				{
					label: '2',
					imgPath: 'images/faq/always_on_display/2.jpg'
				},
				{
					label: '3',
					imgPath: 'images/faq/always_on_display/3.jpg'
				}
			]}
		/>
	</React.Fragment>
);

const config = [
	{
		id: 'reset',
		title: '1. How to factory reset the phone?',
		content: (
			<React.Fragment>
				<Typography paragraph>
					If the phone is brand new from the box, a factory reset is not necessary and
					it's already ready for installation when powered on.
				</Typography>
				<Typography>
					Before the factory reset, remove any Google accounts from the phone. If the
					phone is powered and functional, use Android Settings → General management →
					Reset → Factory data reset.
				</Typography>
			</React.Fragment>
		)
	},
	{
		id: 'stuck',
		title: '2. I cannot complete the setup! What to do?',
		content: (
			<React.Fragment>
				<Typography>
					<b>
						Error: "Your device has reset protection turned on. For help, contact your
						admin."
					</b>
				</Typography>
				<Typography paragraph>
					If the phone is reset when an active Google account is present, the phone will
					enter Factory Reset Protection (FRP) mode. The setup cannot be completed when in
					FRP mode. The FRP mode can be turned off by signing in using the previously used
					Google account, but this has to be done through the normal Android setup process
					(do not use the QR code in this phase).
				</Typography>
				<Typography>
					<b>Other errors:</b>
				</Typography>
				<Typography paragraph>
					Try to factory reset your phone using the instructions in the previous FAQ
					section.
				</Typography>
				<Typography>
					Contact <Link href="mailto:helpdesk@vaisala.com">helpdesk@vaisala.com</Link> if
					you're still unable to complete the setup.
				</Typography>
			</React.Fragment>
		)
	},
	{
		id: 'mobile_data',
		title: '3. How to enable mobile data?',
		content: enableMobileDataContent
	},
	{
		id: 'sim_lock',
		title: '4. How to disable SIM lock?',
		content: simLockContent
	},
	{
		id: 'always_on',
		title: '5. How to disable Always On Display?',
		content: alwaysOnDisplayContent
	}
];

export function FAQ() {
	return (
		<div>
			<Typography variant="subtitle2" paragraph>
				Following instructions may differ depending on your phone.
			</Typography>
			<CollapsePanels config={config} />
		</div>
	);
}

import React from 'react';
import _ from 'lodash';
import QRCode from 'qrcode.react';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { withResizeDetector } from 'react-resize-detector';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
	collapseTitle: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: -theme.spacing(1),
		cursor: 'pointer'
	},
	expand: {
		transform: 'rotate(0deg)',
		marginLeft: 'auto',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest
		})
	},
	expandOpen: {
		transform: 'rotate(180deg)'
	}
});

const defaultContent = {
	'android.app.extra.PROVISIONING_DEVICE_ADMIN_COMPONENT_NAME':
		'com.vaisala.cv.dpc/.AdminReceiver',
	'android.app.extra.PROVISIONING_DEVICE_ADMIN_SIGNATURE_CHECKSUM':
		'lHt5eKp9dT-9Ypvwt2JSLB_shCX4qp2pBjtOFPZp2Co=',
	'android.app.extra.PROVISIONING_DEVICE_ADMIN_PACKAGE_DOWNLOAD_LOCATION':
		'https://dpc.vionice.io/apk',
	'android.app.extra.PROVISIONING_SKIP_ENCRYPTION': true
};

const QRCodeElement = withResizeDetector(function(props) {
	const { width = 1, qrContent } = props;
	return <QRCode className="qrcode" renderAs="svg" value={qrContent} size={width} />;
});

class QRCodeGeneration extends React.Component {
	state = {
		qrContent: JSON.stringify(defaultContent),
		networkName: '',
		networkPassword: '',
		nameError: false,
		showPassword: false
	};

	handleNetworkNameChange = event => {
		this.setState({ networkName: event.target.value });
	};

	handleNetworkPasswordChange = event => {
		this.setState({ networkPassword: event.target.value });
	};

	handleGenerate = () => {
		const { networkName, networkPassword } = this.state;

		let qrContent = _.clone(defaultContent);

		if (networkPassword && !networkName) {
			this.setState({ nameError: true });
			return;
		}

		if (networkName) {
			let networkNameFinal = '',
				channel = '';
			if (networkName.startsWith('alpha@')) {
				channel = 'alpha';
				networkNameFinal = networkName.substring(6);
			} else if (networkName.startsWith('beta@')) {
				channel = 'beta';
				networkNameFinal = networkName.substring(5);
			} else {
				networkNameFinal = networkName;
			}

			const properties = {
				'android.app.extra.PROVISIONING_DEVICE_ADMIN_PACKAGE_DOWNLOAD_LOCATION': `https://dpc.vionice.io/apk?channel=${channel}`,
				'android.app.extra.PROVISIONING_ADMIN_EXTRAS_BUNDLE': { channel }
			};

			if (networkNameFinal) {
				properties['android.app.extra.PROVISIONING_WIFI_SSID'] = networkNameFinal;
			}

			_.assign(qrContent, properties);
		}

		if (networkPassword) {
			const networkCredentials = {
				'android.app.extra.PROVISIONING_WIFI_PASSWORD': networkPassword,
				'android.app.extra.PROVISIONING_WIFI_SECURITY_TYPE': networkPassword
					? 'WPA'
					: 'NONE'
			};
			_.assign(qrContent, networkCredentials);
		}

		this.setState({ qrContent: JSON.stringify(qrContent), nameError: false });
	};

	handleClickShowPassword = () => {
		this.setState({ showPassword: !this.state.showPassword });
	};

	handleMouseDownPassword = event => {
		event.preventDefault();
	};

	render() {
		const {
			qrContent,
			networkName,
			networkPassword,
			nameError,
			showPassword
		} = this.state;

		const { noOptions, spacing = 3 } = this.props;

		const networkNameField = (
			<TextField
				fullWidth
				required
				label="Wifi network name"
				variant="filled"
				error={nameError}
				helperText={nameError && 'Please input network name'}
				value={networkName}
				onChange={this.handleNetworkNameChange}
			/>
		);

		const networkPasswordField = (
			<TextField
				fullWidth
				label="Wifi network password"
				variant="filled"
				type={showPassword ? 'text' : 'password'}
				value={networkPassword}
				onChange={this.handleNetworkPasswordChange}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton
								edge="end"
								aria-label="Toggle password visibility"
								onClick={this.handleClickShowPassword}
								onMouseDown={this.handleMouseDownPassword}
							>
								{!showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					)
				}}
			/>
		);

		const button = (
			<Button fullWidth onClick={this.handleGenerate} color="primary" variant="outlined">
				Generate QR code
			</Button>
		);

		let optionsElement = null;
		if (!noOptions) {
			optionsElement = (
				<Grid item xs={12}>
					<Grid container spacing={spacing}>
						<Grid item xs={12}>
							{networkNameField}
						</Grid>
						<Grid item xs={12}>
							{networkPasswordField}
						</Grid>
						<Grid item xs={12}>
							{button}
						</Grid>
					</Grid>
				</Grid>
			);
		}

		return (
			<Grid container spacing={spacing}>
				{optionsElement}
				<Grid item xs={12}>
					<QRCodeElement qrContent={qrContent} />
				</Grid>
			</Grid>
		);
	}
}

export default withStyles(styles)(QRCodeGeneration);

import { SET_MOBILE, SET_ACTIVE_GUIDE_PAGE } from '../actions/types';

const initialState = {
	isMobile: null,
	guidePage: 0
};

export default function(state = initialState, action) {
	switch (action.type) {
		case SET_MOBILE:
			return {
				...state,
				isMobile: action.payload
			}
		case SET_ACTIVE_GUIDE_PAGE:
			return {
				...state,
				guidePage: action.payload
			}
		default:
			return state;
	}
}
